@charset "UTF-8";
body {
  background-color: #0e1116 !important;
  color: rgba(255, 255, 255, 0.9215686275);
  margin: 0%;
}

.row {
  margin-left: 0%;
}

.home {
  background-color: #0d0d0d;
  padding-left: 5%;
  padding-right: 5%;
}

.navbar {
  margin: 30px 0% !important;
  padding: 0% 5% !important;
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  background-color: #0d0d0d !important;
  z-index: 1 !important;
}
.navbar a {
  color: white;
}
.navbar a:hover {
  background-color: #2c2c2c;
  color: white;
  border-radius: 15px;
}
.navbar .dropdown-menu {
  background-color: #2c2c2c;
  z-index: 10000000000000000000000 !important;
}
.navbar .dropdown-menu a,
.navbar .dropdown-menu .dropdown-item {
  color: white;
  text-decoration: none !important;
}
.navbar .dropdown-menu a p,
.navbar .dropdown-menu .dropdown-item p {
  margin-bottom: 0%;
}
.navbar .dropdown-menu a span,
.navbar .dropdown-menu .dropdown-item span {
  color: #7e7e7e;
}
.navbar .dropdown-menu a:hover,
.navbar .dropdown-menu .dropdown-item:hover {
  background-color: #353535 !important;
  color: white;
  border-radius: 15px;
}
.navbar button {
  border-radius: 13px;
  border-color: rgba(255, 255, 255, 0.1019607843);
}
.navbar .login-button {
  background-color: rgba(255, 255, 255, 0.0549019608);
}
.navbar .login-button:hover {
  background-color: rgba(255, 255, 255, 0.1019607843);
}

.moadl {
  position: absolute;
  z-index: 999999 !important;
}

.modal-content {
  background-color: #2c2c2c !important;
}
.modal-content .nav-modal-links {
  padding-bottom: 0px;
  margin-bottom: 0px;
  width: 100% !important;
  height: 300px;
  overflow-y: auto !important;
  /* Style the scrollbar */
}
.modal-content .nav-modal-links .row {
  width: -moz-max-content;
  width: max-content;
  padding: 10px;
}
.modal-content .nav-modal-links::-webkit-scrollbar {
  width: 8px;
}
.modal-content .nav-modal-links::-webkit-scrollbar-thumb {
  background-color: #757373;
  border-radius: 10px;
}
.modal-content .nav-modal-links::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.2274509804);
}
.modal-content .nav-modal-links::-webkit-scrollbar-track {
  background-color: #757373;
  border-radius: 10px;
}
.modal-content a {
  color: white;
  text-decoration: none;
}
.modal-content a:hover {
  background-color: #2c2c2c;
  color: white;
  border-radius: 15px;
}
.modal-content hr {
  height: 1px;
  color: #4d4c4c;
}
.modal-content .nav-modal-buttons .col {
  padding: 8px;
}
.modal-content .nav-modal-buttons button {
  width: 100%;
  border-radius: 13px;
  border-color: rgba(255, 255, 255, 0.1019607843);
}

.header {
  width: 100%;
  border: 1px solid rgb(104, 104, 104);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  margin: auto;
  padding-bottom: 150px;
  background: url(../images/header-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.header .header-content {
  margin-left: 100px;
  width: 40%;
  margin-top: 200px;
  text-align: left;
}
.header .header-content h1 {
  font-weight: 700;
  color: rgba(255, 255, 255, 0.9215686275);
  font-size: 3em;
}
.header .header-content h6 {
  color: rgba(255, 255, 255, 0.5921568627);
}
.header .header-content input {
  margin-top: 20px;
  background-color: #0d0d0d;
  border: 1px solid #484848;
  color: white;
  border-left: none;
  padding: 10px 20px;
  border-radius: 15px;
}
.header .header-content input::-moz-placeholder {
  color: #5e5e5e;
}
.header .header-content input::placeholder {
  color: #5e5e5e;
}
.header .header-content .input-group {
  width: 80%;
}
.header .header-content .input-group-text {
  background-color: #0d0d0d;
  border: 1px solid #484848;
  color: #ececec;
  padding-top: 0;
  margin-top: 20px;
  padding-left: 15px;
  border-right: none;
  font-size: 14px;
  border-radius: 15px;
}

.news {
  margin-bottom: 50px;
}
.news .card {
  text-align: left;
  color: white;
  padding: 30px;
  border-radius: 15px;
  margin-bottom: 10px;
}
.news .card a {
  text-decoration: none;
  color: white;
}
.news .left-card {
  background: url(../images/left-news-card-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.news .right-card {
  background: url(https://zealy.io/_next/static/media/pink.2ba25a53.webp);
  background-repeat: no-repeat;
  background-size: cover;
}

.filters {
  position: sticky;
  top: 78px;
  padding-top: 12px;
  background-color: #0d0d0d !important;
  z-index: 2;
}
.filters button {
  background-color: transparent !important;
  border-radius: 15px;
  border-color: #2c2c2c;
  font-size: 12px;
}
.filters .right-filters-container {
  position: relative;
}
.filters .scroll-container {
  display: flex;
  overflow-x: auto;
  scrollbar-width: none;
  /* For Firefox */
  -ms-overflow-style: none;
  /* For Internet Explorer and Edge */
  /* Hide the scrollbar in WebKit-based browsers */
}
.filters .scroll-container::-webkit-scrollbar {
  display: none;
}
.filters .scroll-button {
  position: absolute;
  top: -5px;
  background-color: #141414 !important;
  font-size: 18px !important;
  cursor: pointer;
  border-radius: 20px;
  z-index: 1;
}
.filters .left-scroll-button {
  left: 0;
}
.filters .right-scroll-button {
  right: 0;
}

.primary-card {
  width: 500px !important;
  margin-right: 20px;
}
.primary-card .card {
  background-color: transparent;
  color: white;
  width: 500px;
  border: 1px solid rgb(34, 34, 34);
  border-radius: 15px;
  padding: 15px;
}
.primary-card .card:hover {
  background: linear-gradient(to right, #1b1b1b, #0d0d0d);
  padding: 13px;
  border-color: rgb(44, 44, 44);
}
.primary-card .card .card-text {
  color: rgba(255, 255, 255, 0.5921568627);
  font-weight: 500;
}
.primary-card .card img {
  width: 60px !important;
  border-radius: 15px;
}
.primary-card .card .links-row a {
  text-decoration: none;
  color: white;
  background-color: rgba(255, 255, 255, 0.1019607843);
  padding: 0px 20px;
  border-radius: 15px;
  height: 45px;
  display: flex;
  align-items: center;
  gap: 10px;
  width: -moz-fit-content;
  width: fit-content;
}

.primary-card-small .card {
  padding: 0;
}
.primary-card-small .card img {
  width: 40px !important;
  border-radius: 5px;
}
.primary-card-small .card .card-body {
  padding: 5px 10px;
}

.new-communities {
  margin-top: 60px;
}
.new-communities h6 {
  color: rgba(255, 255, 255, 0.9215686275);
  font-weight: 700;
  width: -moz-fit-content;
  width: fit-content;
}
.new-communities button {
  background-color: rgba(59, 58, 58, 0.4862745098);
  border-radius: 16px;
  border: none;
}
.new-communities button:hover {
  background-color: rgba(44, 43, 43, 0.0549019608);
  border-radius: 16px;
  border: none;
}
.new-communities .cards-row-container {
  overflow-x: auto;
}
.new-communities .cards-row-container::-webkit-scrollbar {
  display: none;
}
.new-communities .cards-row {
  display: flex;
  width: -moz-max-content;
  width: max-content;
  flex-wrap: nowrap;
}

.community-categories .card {
  background-color: transparent;
  color: white;
  width: 250px;
  margin-right: 20px;
  border: 1px solid rgb(34, 34, 34);
  border-radius: 25px;
  padding: 25px 0;
  background-size: 120% 130%;
  background-position: center;
}

.shape-future .card {
  background-repeat: no-repeat;
  background-size: 120% 120%;
  background-position: center;
  height: -moz-fit-content;
  height: fit-content;
}
.shape-future .card a {
  background-color: rgba(255, 255, 255, 0.0549019608);
  border-radius: 20px;
  padding: 20px;
  margin-top: 20px;
  display: block;
  width: -moz-max-content;
  width: max-content;
}

.footer h6 {
  color: white;
  margin-bottom: 20px;
}
.footer img {
  margin-bottom: 20px;
}
.footer p {
  color: rgba(255, 255, 255, 0.5921568627);
  width: -moz-fit-content;
  width: fit-content;
}
.footer i {
  width: -moz-fit-content;
  width: fit-content;
  color: rgba(255, 255, 255, 0.5921568627);
}
.footer hr {
  color: rgba(255, 255, 255, 0.5921568627);
}

.welcome {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 10px 0px 10px;
  background-color: #0e1116;
}
.welcome .welcome-navbar .fa-map {
  color: #e34ba9;
}
.welcome .welcome-navbar .nav-link-quests {
  background-color: #2c2c2c;
  border-radius: 30px;
}

.welcome-sidebar {
  background-color: #161616;
  border-radius: 25px;
  width: -moz-max-content;
  width: max-content;
  padding: 10px;
  position: fixed;
  height: 98%;
  text-align: center;
}
.welcome-sidebar i {
  color: rgba(255, 255, 255, 0.9215686275);
  border-color: rgba(255, 255, 255, 0.0549019608);
  background-color: rgba(255, 255, 255, 0.0549019608);
  display: block;
  padding: 15px;
  margin: 20px auto;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 18px;
  border-radius: 18px;
  border: 0.8px solid #3d3d3d;
}

.welcome-navbar {
  background-color: #161616;
  border-radius: 25px;
  width: 230px;
  margin-left: 80px;
  padding: 0px;
  position: fixed;
  height: 98%;
  text-align: left;
  color: white;
}
.welcome-navbar .nav-top {
  padding: 70px 15px 5px;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(https://crew3-production.s3.eu-west-3.amazonaws.com/public/5d67cdf1-9abe-4d1b-afba-ce9bf016493f-profile.png);
  background-size: 110% 120%;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 30px;
}
.welcome-navbar .nav-top button {
  background: none;
  border: none;
  padding: 0%;
  margin: 0%;
  cursor: pointer;
}
.welcome-navbar .nav-top .dropdown-menu {
  width: 200px;
  margin-top: 30px;
  padding: 5px;
  background-color: rgb(40, 40, 40);
  border-radius: 20px;
}
.welcome-navbar .nav-top .dropdown-menu .dropdown-item {
  color: white;
  padding: 5px 10px;
}
.welcome-navbar .nav-top .dropdown-menu .dropdown-item:hover {
  background-color: #3b3b3b;
  border-radius: 10px;
}
.welcome-navbar .nav-top .dropdown-menu .dropdown-footer .dropdown-item {
  color: #e75c61;
  padding: 5px 10px;
}
.welcome-navbar .nav-top .dropdown-menu .dropdown-footer .dropdown-item:hover {
  background-color: #442b2b;
  border-radius: 10px;
}
.welcome-navbar a {
  text-decoration: none;
  color: white;
}
.welcome-navbar .nav-link {
  padding: 10px 7.5px;
  margin: 5px 7.5px;
  cursor: pointer;
}
.welcome-navbar .nav-link i {
  color: #666666;
}
.welcome-navbar .nav-link:hover {
  background-color: #2c2c2c;
  border-radius: 30px;
}

.quests {
  margin-left: 333px;
  padding: 16px;
  color: #7e7e7e;
  font-weight: bold;
}
.quests .logo {
  width: 200px;
}
.quests h2 {
  color: white;
}
.quests button {
  margin-top: 20px;
  margin-right: 10px;
  background-color: transparent;
  border-color: #4d4c4c;
}
.quests button:hover {
  background-color: rgba(39, 39, 39, 0.4666666667);
}
.quests button .fa-hands-clapping {
  color: rgb(228, 133, 10);
}
.quests button .fa-fire {
  color: rgb(255, 60, 0);
}
.quests #👋-find-your-zeal-heading {
  color: #8e7aff;
  margin: 40px 0px 30px;
}
.quests .👋-find-your-zeal .welcome-card {
  width: 350px;
  border: 1px solid #6054ab;
  padding: 20px 10px;
  background: linear-gradient(45deg, #13151f, #1a1c2b);
  border-radius: 5px;
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
}
.quests .👋-find-your-zeal .welcome-card:hover {
  background: linear-gradient(45deg, #1a1c2b, #13151f);
}
.quests .👋-find-your-zeal .welcome-card h4 {
  color: white;
}
.quests .👋-find-your-zeal .welcome-card p {
  font-weight: 400;
  color: #cecece;
  height: 50px;
  overflow: hidden;
}
.quests #🔥-prepare-for-lift-off-heading {
  color: #f5c001;
  margin: 40px 0px 30px;
}
.quests .🔥-prepare-for-lift-off .welcome-card {
  width: 350px;
  border: 1px solid #7e650c;
  padding: 20px 10px;
  background: linear-gradient(45deg, #212014, #141615);
  border-radius: 5px;
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
}
.quests .🔥-prepare-for-lift-off .welcome-card:hover {
  background: linear-gradient(-45deg, #212014, #141615);
}
.quests .🔥-prepare-for-lift-off .welcome-card h4 {
  color: white;
}
.quests .🔥-prepare-for-lift-off .welcome-card p {
  font-weight: 400;
  color: #cecece;
  height: 50px;
  overflow: hidden;
}
.quests .🔥-prepare-for-lift-off .welcome-card #xp-btn {
  visibility: hidden;
}

.leaderboard {
  display: flex;
  align-items: center;
  padding: 10px 0px 55px 0px;
  background-color: #0e1116;
}
.leaderboard .welcome-navbar .fa-chart-simple {
  color: #e34ba9;
}
.leaderboard .welcome-navbar .nav-link-leaderboard {
  background-color: #2c2c2c;
  border-radius: 30px;
}
.leaderboard .leaderboard-content {
  margin-left: 333px;
  padding: 60px 16px 16px;
  font-weight: bolder;
  color: white;
}
.leaderboard .leaderboard-content h1 {
  font-size: 2.5em;
}
.leaderboard .leaderboard-content .leaderboard-table {
  background-color: #171a1f;
  border-radius: 5px;
  padding: 20px 0px;
  height: 450px;
  overflow-y: auto;
  font-size: 12px;
  font-weight: 400;
  /* Style the scrollbar */
}
.leaderboard .leaderboard-content .leaderboard-table .col-auto {
  padding: 5px !important;
}
.leaderboard .leaderboard-content .leaderboard-table::-webkit-scrollbar {
  width: 8px;
}
.leaderboard .leaderboard-content .leaderboard-table::-webkit-scrollbar-thumb {
  background-color: rgb(92, 92, 92);
  border-radius: 10px;
}
.leaderboard .leaderboard-content .leaderboard-table::-webkit-scrollbar-thumb:hover {
  background-color: #a5a4a4;
}
.leaderboard .leaderboard-content .leaderboard-table::-webkit-scrollbar-track {
  background-color: rgb(44, 44, 44);
  border-radius: 10px;
}
.leaderboard .leaderboard-content .leaderboard-table img {
  width: 30px;
  border-radius: 50%;
}
.leaderboard .leaderboard-content .leaderboard-table span {
  color: #1bdab7;
}
.leaderboard .leaderboard-content .leaderboard-table .table-row {
  padding: 10px 20px;
  width: 100%;
  cursor: pointer;
}
.leaderboard .leaderboard-content .leaderboard-table .table-row:hover {
  background-color: #101216;
}

.welcome-top-navbar {
  display: none;
}

.post-link {
  text-decoration: none;
}
.post-link h3 {
  color: white;
}

.explore-parent {
  background-color: #0d0d0d;
}
.explore-parent .navbar-parent {
  background-color: #0d0d0d;
  width: 100%;
}
.explore-parent .navbar {
  border: 1px solid rgb(80, 80, 80);
  padding: 20px 20px !important;
  width: 95%;
  margin: auto !important;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: #0d0d0d !important;
}

.explore {
  background-color: #0d0d0d;
  margin-top: -100px;
}
.explore .row {
  margin: 0%;
}
.explore .filters {
  position: sticky;
  top: 90px;
  margin-top: 90px;
  padding: 20px 2% 20px 2%;
  z-index: 1000;
}
.explore .explore-content {
  padding: 150px 35px 0px 35px;
  color: white;
}
.explore .explore-content p {
  color: #969696;
}
.explore .explore-content .btn-group {
  background-color: transparent;
}
.explore .explore-content .btn-group .active {
  background-color: #262626;
}
.explore .explore-content .primary-card {
  width: 98% !important;
  margin: auto;
  margin-bottom: 20px;
}
.explore .explore-content .primary-card .card {
  width: 99% !important;
  padding: 5px;
}
.explore .explore-content .primary-card .card .col,
.explore .explore-content .primary-card .card .col-auto {
  padding-left: 0%;
}
.explore .explore-content .footer {
  margin-top: 100px;
}

.dropdown-menu {
  z-index: 300000000 !important;
}

.signup {
  margin: 0%;
}
.signup .row {
  width: 100%;
  margin: 0%;
}
.signup .signup-content {
  width: 90%;
  margin: auto;
}
.signup .signup-left {
  color: white;
  background-color: #161616;
  padding: 36px 20px !important;
  height: 110vh;
}
.signup .signup-left p,
.signup .signup-left span {
  color: #8e8e8e;
}
.signup .signup-left button {
  background-color: #282828;
  border-color: #393939;
  border-radius: 15px;
}
.signup .signup-left .signup-header button {
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
}
.signup .signup-left form button {
  width: 100%;
  margin-top: 20px;
  padding: 10px;
}
.signup .signup-left form input {
  background-color: transparent;
  border-color: #393939;
  border-radius: 15px;
  margin-top: 20px;
  padding: 10px;
}
.signup .signup-left form input::-moz-placeholder {
  color: #646464;
}
.signup .signup-left form input::placeholder {
  color: #646464;
}
.signup .signup-right {
  background: url(https://zealy.io/nstatic/auth.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.create-community {
  position: relative;
  height: 100vh;
  overflow: hidden;
}
.create-community .home-wrapper .home {
  position: absolute;
  width: 100%;
  top: -1150px;
  left: 0;
  z-index: 10;
}
.create-community .home-wrapper .home::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(22, 22, 22, 0.658);
  z-index: 2;
}
.create-community .home-wrapper .home::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  cursor: pointer;
}
.create-community .home-wrapper .home .navbar {
  position: static !important;
}
.create-community .home-wrapper .home .filters {
  position: static !important;
}
.create-community .create-community-content {
  padding: 110px 6.5%;
  background-color: #161616;
  width: 600px;
  color: white;
  height: 100vh;
  overflow: auto;
  position: relative;
  z-index: 100;
  /* Style the scrollbar */
}
.create-community .create-community-content::-webkit-scrollbar {
  width: 8px;
}
.create-community .create-community-content::-webkit-scrollbar-thumb {
  background-color: rgb(92, 92, 92);
  border-radius: 10px;
}
.create-community .create-community-content::-webkit-scrollbar-thumb:hover {
  background-color: #a5a4a4;
}
.create-community .create-community-content::-webkit-scrollbar-track {
  background-color: rgb(44, 44, 44);
  border-radius: 10px;
}
.create-community .create-community-content p {
  color: #9c9c9c;
}
.create-community .create-community-content button {
  background-color: #282828;
  border-color: #393939;
  border-radius: 15px;
}
.create-community .create-community-content button:hover, .create-community .create-community-content button:active, .create-community .create-community-content button:focus {
  background-color: #282828;
}
.create-community .create-community-content input {
  background-color: transparent;
  border-color: #393939;
  border-radius: 15px;
  margin-top: 20px;
  padding: 10px;
}
.create-community .create-community-content input::-moz-placeholder {
  color: #646464;
}
.create-community .create-community-content input::placeholder {
  color: #646464;
}
.create-community .create-community-content input:active {
  background-color: transparent;
  border-color: #393939;
}
.create-community .create-community-content input:focus {
  background-color: transparent;
  border-color: #393939;
}
.create-community .create-community-content #fileInput {
  display: none;
}
.create-community .create-community-content .upload-button {
  padding: 30px 37.5px;
  background-color: transparent;
  border-color: #393939;
  margin-top: 10px;
  color: #a0a0a0;
  font-size: 20px;
}
.create-community .create-community-content textarea {
  background-color: transparent;
  border-color: #393939;
  border-radius: 15px;
  margin-top: 20px;
  padding: 10px;
}
.create-community .create-community-content textarea::-moz-placeholder {
  color: #646464;
}
.create-community .create-community-content textarea::placeholder {
  color: #646464;
}
.create-community .create-community-content textarea:active {
  background-color: transparent;
  border-color: #393939;
}
.create-community .create-community-content textarea:focus {
  background-color: transparent;
  border-color: #393939;
}
.create-community .create-community-content select {
  background-color: transparent;
  border-color: #393939;
  border-radius: 15px;
  margin-top: 20px;
  padding: 10px;
  color: white;
}
.create-community .create-community-content select:active {
  background-color: transparent;
  border-color: #393939;
}
.create-community .create-community-content select:focus {
  background-color: transparent;
  border-color: #393939;
}
.create-community .create-community-content select option {
  color: white;
  background-color: #393939;
}

.changelog {
  background-color: #0d0d0d;
}
.changelog .changelog-header {
  background: url(../images/changelog-header-bg.png);
  background-blend-mode: screen;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 240px 0 130px;
}
.changelog .changelog-header p {
  color: #f65cb6;
}
.changelog .changelog-header h1 {
  color: white;
  font-size: calc(1.375rem + 1.5vw);
  margin-bottom: 30px;
  font-weight: bolder;
}
.changelog .changelog-header h6 {
  color: #949494;
  margin-bottom: 15px;
}
.changelog .changelog-header a {
  color: #d14999;
  padding: 15px;
}

@media (max-width: 1035px) {
  .second-div {
    display: none;
  }
  .third-div {
    margin-left: auto;
  }
  .explore-content .btn-group {
    display: none;
  }
}
@media (max-width: 767px) {
  .navbar {
    padding: 30px 1% !important;
  }
  .third-div {
    padding: 0% !important;
  }
  .third-div .navbar-nav {
    flex-direction: row;
  }
  .third-div .nav-link {
    display: none !important;
  }
  .header {
    width: 98%;
  }
  .header .header-content {
    margin-left: 30px;
    width: 80%;
  }
  .header .header-content h1 {
    font-size: 2em;
  }
  .hidden {
    display: none;
  }
  .welcome .quests {
    margin-left: 0%;
  }
  .welcome .quests .welcome-card {
    width: 100%;
  }
  .welcome-top-navbar {
    display: block;
    text-align: left;
    color: white;
    padding: 15px 30px;
    background-color: #0e1116;
  }
  .welcome-top-navbar button {
    background-color: transparent;
    border: none;
    padding: 0%;
  }
  .welcome-top-navbar img {
    width: 32px;
    margin: 0px 20px;
  }
  .leaderboard .leaderboard-content {
    margin-left: 0%;
  }
  .leaderboard .leaderboard-content .leaderboard-table {
    width: 100%;
  }
  .cards-row-small {
    visibility: hidden;
  }
  .create-community-content {
    width: 100% !important;
  }
}
@media (min-width: 967px) {
  .leaderboard-table {
    width: 600px;
  }
}
@media (min-width: 1600px) {
  .explore-parent .navbar {
    width: 1600px;
    margin: auto;
  }
  .explore {
    width: 1600px;
    margin: auto;
  }
  .create-community-content {
    height: 100vh;
  }
}/*# sourceMappingURL=index.css.map */